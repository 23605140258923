var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3",
    attrs: {
      "max-width": "800",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v(_vm._s(_vm.setTitle) + " 상세")]), ['agency'].includes(_vm.form.code) ? [_c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("상호명")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.store,
      callback: function ($$v) {
        _vm.$set(_vm.form, "store", $$v);
      },
      expression: "form.store"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("이름")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("연락처")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("개설희망지역")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.region,
      callback: function ($$v) {
        _vm.$set(_vm.form, "region", $$v);
      },
      expression: "form.region"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("상담가능시간")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1)], 1)], 1)], 1)] : _vm._e(), ['estimate'].includes(_vm.form.code) ? [_c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("유형")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("회사명")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.company,
      callback: function ($$v) {
        _vm.$set(_vm.form, "company", $$v);
      },
      expression: "form.company"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("이름")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("이메일")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("연락처")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("문의제목")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("문의내용")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-textarea', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1)], 1), _c('v-divider')], 1)] : _vm._e(), ['as'].includes(_vm.form.code) ? [_c('v-row', [_c('v-col', {
    attrs: {
      "justify": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "href": _vm.form.file,
      "download": "",
      "color": "primary"
    }
  }, [_vm._v("폼메일 파일 다운로드")])], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("유형")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("회사명")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.company,
      callback: function ($$v) {
        _vm.$set(_vm.form, "company", $$v);
      },
      expression: "form.company"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("이름")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("이메일")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("연락처")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("주소")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.zipcode,
      callback: function ($$v) {
        _vm.$set(_vm.form, "zipcode", $$v);
      },
      expression: "form.zipcode"
    }
  }), _c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.address1,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address1", $$v);
      },
      expression: "form.address1"
    }
  }), _c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.address2,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address2", $$v);
      },
      expression: "form.address2"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("구입시기")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.purchasedAt,
      callback: function ($$v) {
        _vm.$set(_vm.form, "purchasedAt", $$v);
      },
      expression: "form.purchasedAt"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("구입한 대리점")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.store,
      callback: function ($$v) {
        _vm.$set(_vm.form, "store", $$v);
      },
      expression: "form.store"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("하자증상")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.symptom,
      callback: function ($$v) {
        _vm.$set(_vm.form, "symptom", $$v);
      },
      expression: "form.symptom"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("제품 모델 번호")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.modelNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "modelNo", $$v);
      },
      expression: "form.modelNo"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("요청내용")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-textarea', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1)], 1), _c('v-divider')], 1)] : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("확인")])], 1)], 1)], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }