<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">컬러 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3">
                            <v-col cols="6" md="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table :items="colors" :headers="colorsHeaders" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+colors.indexOf(item) + 1) }}
                </template>
                <template #[`item.meta.url`]="{ value }">
                    <v-btn v-if="!!value" icon text :href="value" target="_blank">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                    <template v-else>-</template>
                </template>
				<template #[`item.image`]="{ item }">
                    <v-row>
                        <v-img v-if="item.image" :src="item.image.src" width="54" height="54" contain></v-img>
                    </v-row>
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ item.createdAt.toDateTime() }}
                </template>
                <template #[`item.actions`]="{item}">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
		<v-dialog v-model="dialog" max-width="960px">
			<v-card >
				<v-card-title>
					<span v-if="editItem._id" class="subtitle-1 font-weight-bold">컬러수정</span>
					<span v-else class="subtitle-1 font-weight-bold">컬러추가</span>
				</v-card-title>

				<v-card-text class="py-0">
					<v-row align="center">
						<v-col cols="2">
							이미지
							<div>이미지사이즈 : 282x370(px)</div>
						</v-col>
						<v-col cols="10">
							<div>
								<template v-if="editItem.image">
									<v-layout>
										<v-spacer/>
										<v-btn icon text @click="editItem.image = null"><v-icon size="18">mdi-close</v-icon></v-btn>
									</v-layout>
									<v-card outlined width="100%" style="border-style: dotted; border-width: 2px;">
										<v-img :src="editItem.image.src" height="373" contain />
									</v-card>
								</template>
								<v-sheet v-else outlined width="100%" height="373" class="d-flex" style="border-style: dotted; border-width: 2px; cursor: pointer;" @click="$refs.image?.$refs?.input?.click?.()">
									<div class="ma-auto">
										<v-icon left size="18">mdi-file-upload</v-icon>
										<span>업로드 (.jpg, .png)</span>
									</div>
								</v-sheet>
								<v-spacer/>
							</div>
							<v-file-input ref="image" class="d-none" @change="file => upload(file).then(res => $set(editItem, 'image', res))" />
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-text class="py-0">
					<v-row align="center">
						<v-col cols="2">코드</v-col>
						<v-col cols="10">
							<v-text-field v-model="editItem.code" dense outlined hide-details />
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider />

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="close">취소</v-btn>
					<v-btn text color="primary" @click="save">저장</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField,
    },
    data() {
        return {
			dialog: false,
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
                category: this.$route.query.category,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            colors: [],
			editItem: {
				image: null,
				code: null,
			},

            colorsHeaders: [
                { text: "no", align: "center", width: 40, value: "no" },
                { text: "이미지", align: "center", width: 120, value: "image" },
                { text: "코드", align: "center", width: 100, value: "code" },
                { text: "Actions", width: 40, align: "center", value: "actions" },
            ],

            searchKeys: [
                { text: "전체", value: null },
                { text: "코드", value: "code" },
            ],
            searchKey: null,
            searchValue: null,
        };
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
    methods: {
        async init() {
            await this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, colors } = await api.console.colors.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.colors = colors;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
		async upload(files){
            if(!Array.isArray(files)) return await api.resource.post(files, `/res/colors`);

            let _ = [];
            for(let file of files){
                _.push(await api.resource.post(file, `/res/colors`));
            }

            return _;
        },
        create() {
            this.dialog = true;
        },
        edit(color) {
            this.editItem = color;
			this.dialog = true
        },
		close() {
			this.dialog = false
		},
		async save() {

			let { success } = this.editItem._id ? await api.console.colors.put(this.editItem) : await api.console.colors.post(this.editItem);
			
			if (success) {
				alert("저장되었습니다.");
				this.dialog = false;
				this.editItem = {
					image: null,
					code: null
				}
				await this.init()
			}
		},
        async remove(board) {
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    await api.console.colors.delete(board);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>
