<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="800" width="100%" height="100%" class="px-3">
            <div class="headline text-start mt-8">{{ setTitle }} 상세</div>

            <template v-if="['agency'].includes(form.code)">
                <v-card class="mt-4">
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">상호명</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.store" dense outlined hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">이름</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.name" dense outlined hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">연락처</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.phone" dense outlined hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">개설희망지역</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.region" dense outlined hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">상담가능시간</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.remark" dense outlined hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>

            <template v-if="['estimate'].includes(form.code)">
                <v-card class="mt-4">
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">유형</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.type" dense outlined hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">회사명</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.company" dense outlined hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">이름</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.name" dense outlined hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">이메일</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.email" dense outlined hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">연락처</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.phone" dense outlined hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">문의제목</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.subject" dense outlined hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">문의내용</v-col>
                            <v-col cols="10">
                                <v-textarea v-model="form.content" dense outlined hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                </v-card>
            </template>

            <template v-if="['as'].includes(form.code)">
                <v-row>
                    <v-col justify="end"> 
                        <v-btn :href="form.file" download color="primary">폼메일 파일 다운로드</v-btn>
                    </v-col>
                </v-row>
                <v-card class="mt-4">
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">유형</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.type" dense outlined hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">회사명</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.company" dense outlined hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">이름</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.name" dense outlined hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">이메일</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.email" dense outlined hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">연락처</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.phone" dense outlined hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">주소</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.zipcode" dense outlined hide-details readonly />
                                <v-text-field v-model="form.address1" dense outlined hide-details readonly class="mt-2" />
                                <v-text-field v-model="form.address2" dense outlined hide-details readonly class="mt-2" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">구입시기</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.purchasedAt" dense outlined hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">구입한 대리점</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.store" dense outlined hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">하자증상</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.symptom" dense outlined hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">제품 모델 번호</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="form.modelNo" dense outlined hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">요청내용</v-col>
                            <v-col cols="10">
                                <v-textarea v-model="form.content" dense outlined hide-details readonly />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                </v-card>
            </template>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">확인</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import api from "@/api";
import { mdiTagPlus } from "@mdi/js";

export default {
    components: {
        ImagePopup,
        NaverSmarteditor,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            form: {},
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._form) {
                    let { form } = await api.console.forms.get({ _id: this.$route.params._form });

                    this.form = form;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                // await api.console.forms.put(this.form)
                // alert("수정되었습니다.");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    computed: {
        setTitle() {
            let titleMap = {
                agency: "대리점 개설 문의",
                estimate: "온라인견적 문의",
                as: "A/S 문의"
            }
            return titleMap[this.form.code]
        }
    },
};
</script>
