var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "760px"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-layout', {
    staticClass: "mt-4"
  }, [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("팝업 이미지")])]), _c('v-card-text', [_c('v-col', _vm._l(_vm.images, function (image, index) {
    return _c('v-row', {
      key: index
    }, [[_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [image.image ? [_c('v-layout', [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "icon": "",
        "small": "",
        "text": ""
      },
      on: {
        "click": function ($event) {
          image.image = null;
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-img', {
      attrs: {
        "src": image.image.src,
        "width": "100%",
        "height": "96",
        "contain": ""
      }
    })] : [_c('v-sheet', {
      staticClass: "d-flex",
      staticStyle: {
        "cursor": "pointer",
        "border-style": "dashed",
        "border-width": "2px"
      },
      attrs: {
        "outlined": "",
        "rounded": "",
        "width": "100%",
        "height": "146"
      },
      on: {
        "click": function ($event) {
          var _vm$$refs, _vm$$refs$, _vm$$refs$$$refs, _vm$$refs$$$refs$inpu, _vm$$refs$$$refs$inpu2;
          (_vm$$refs = _vm.$refs[`image_${index}`]) === null || _vm$$refs === void 0 ? void 0 : (_vm$$refs$ = _vm$$refs[0]) === null || _vm$$refs$ === void 0 ? void 0 : (_vm$$refs$$$refs = _vm$$refs$.$refs) === null || _vm$$refs$$$refs === void 0 ? void 0 : (_vm$$refs$$$refs$inpu = _vm$$refs$$$refs.input) === null || _vm$$refs$$$refs$inpu === void 0 ? void 0 : (_vm$$refs$$$refs$inpu2 = _vm$$refs$$$refs$inpu.click) === null || _vm$$refs$$$refs$inpu2 === void 0 ? void 0 : _vm$$refs$$$refs$inpu2.call(_vm$$refs$$$refs$inpu);
        }
      }
    }, [_c('div', {
      staticClass: "ma-auto"
    }, [_c('v-icon', {
      attrs: {
        "left": "",
        "size": "20"
      }
    }, [_vm._v("mdi-plus")]), _c('span', [_vm._v("추가")])], 1)]), _c('v-file-input', {
      ref: `image_${index}`,
      refInFor: true,
      staticClass: "d-none",
      attrs: {
        "accept": ".jpg, .png"
      },
      on: {
        "change": function (file) {
          return _vm.upload2(file, index);
        }
      }
    })]], 2)]], 2);
  }), 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }