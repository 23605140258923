<template>
	<v-dialog v-model="dialog" max-width="760px">
		<v-card>
			<v-layout class="mt-4">
				<span class="subtitle-2 font-weight-bold">팝업 이미지</span>
			</v-layout>
			<v-card-text>
				<v-col>
					<!-- 반복 -->
					<v-row v-for="image, index in images" :key="index">
						<template>
							<v-col cols="12">
								<template v-if="image.image">
									<v-layout>
										<v-spacer />
										<v-btn icon small text @click="image.image = null"><v-icon>mdi-close</v-icon></v-btn>
									</v-layout>
									<v-img :src="image.image.src"  width="100%" height="96" contain />
								</template>
								<template v-else>
									<v-sheet outlined rounded width="100%" height="146" class="d-flex" style="cursor: pointer; border-style: dashed; border-width: 2px;" @click="$refs[`image_${index}`]?.[0]?.$refs?.input?.click?.()">
										<div class="ma-auto">
											<v-icon left size="20">mdi-plus</v-icon>
											<span>추가</span>
										</div>
									</v-sheet>
									<v-file-input :ref="`image_${index}`" accept=".jpg, .png" class="d-none" @change="file => upload2(file, index)"/>
								</template>
							</v-col>
						</template>
					</v-row>
					<!-- 반복 -->
				</v-col>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import api from '@/api';

export default {
	data() {
		return {
			dialog: false,
			images: []
		}
	},
	methods: {
		async upload2(file, index) {
            let { src } = await api.resource.post(file, `/res/boards/product/popup`);
            this.$set(this.images[index], "image", { src, url: "" })
        },
		open(images) {
			this.images = images;
			this.dialog = true;
		},

		close() {
			this.dialog = false;
		}
	}
}
</script>