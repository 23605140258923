<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">{{ setTitle }} 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자(시작)" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자(종료)" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-select v-model="filter.searchKey" :items="searchKeys[code]" label="검색조건" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder :disabled="!filter.searchKey" dense hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" rounded text @click="onClickSearch"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
            
            <v-data-table v-if="['agency'].includes(this.code)" :items="forms" :headers="agencyHeaders" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+forms.indexOf(item) + 1) }}
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)" title="수정" class="ml-2">mdi-pencil</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-data-table v-if="['estimate'].includes(this.code)" :items="forms" :headers="estimateHeaders" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+forms.indexOf(item) + 1) }}
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)" title="수정" class="ml-2">mdi-pencil</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-data-table v-if="['as'].includes(this.code)" :items="forms" :headers="asHeaders" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+forms.indexOf(item) + 1) }}
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)" title="수정" class="ml-2">mdi-pencil</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            agencyHeaders: [
                { text: "no", value: "no", align: "center", width: 50 },
                { text: "상호명", value: "store", align: "center", width: 100 },
                { text: "이름", value: "name", align: "center", width: 100 },
                { text: "연락처", value: "phone", align: "center", width: 100 },
                { text: "개설희망지역", value: "region", align: "center", width: 100 },
                { text: "상담가능시간", value: "remark", align: "center", width: 100 },
                { text: "작성일자", value: "createdAt", align: "center", width: 100 },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ],

            estimateHeaders: [
                { text: "no", value: "no", align: "center", width: 50 },
                { text: "유형", value: "type", align: "center", width: 100 },
                { text: "이름", value: "name", align: "center", width: 100 },
                { text: "이메일", value: "email", align: "center", width: 100 },
                { text: "연락처", value: "phone", align: "center", width: 100 },
                { text: "문의제목", value: "subject", align: "center", width: 100 },
                { text: "작성일자", value: "createdAt", align: "center", width: 100 },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ],

            asHeaders: [
                { text: "no", value: "no", align: "center", width: 50 },
                { text: "유형", value: "type", align: "center", width: 100 },
                { text: "이름", value: "name", align: "center", width: 100 },
                { text: "이메일", value: "email", align: "center", width: 100 },
                { text: "연락처", value: "phone", align: "center", width: 100 },
                { text: "작성일자", value: "createdAt", align: "center", width: 100 },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            forms: [],

            // searchKeys: [
            //     { text: ":: 전체 ::", value: null },
            //     { text: "작성자", value: "meta.name" },
            //     { text: "회사", value: "meta.company" },
            //     { text: "전화번호", value: "meta.phone" },
            //     { text: "이메일", value: "meta.email" },                
            // ],

            searchKeys: {
                "agency": [
                    { text: ":: 전체 ::", value: null },
                    { text: "상호명", value: "store" },
                    { text: "이름", value: "name" },
                    { text: "연락처", value: "phone" }, 
                ],
                "estimate": [
                    { text: ":: 전체 ::", value: null },    
                    { text: "회사명", value: "company" },
                    { text: "이름", value: "name" },
                    { text: "이메일", value: "email" },
                    { text: "연락처", value: "phone" },   
                ],
                "as": [
                    { text: ":: 전체 ::", value: null },    
                    { text: "이름", value: "name" },
                    { text: "회사명", value: "company" },
                    { text: "전화번호", value: "phone" },
                ]
            },
            searchKey: null,
            searchValue: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, forms } = await api.console.forms.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.forms = forms;
                this.summary = summary;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        edit(form) {
            this.$router.push(`/console/forms/${form._id}`);
        },
        async email(form) {
            try {
                if (confirm(`${form.isEmailed ? "답변 이메일 전송 이력이 있습니다.\n계속해서 " : ""}답변 이메일을 전송하시겠습니까?`)) {
                    await api.console.forms.email(form);
                    await this.search(false);

                    alert("전송되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async remove(form) {
            try {
                if (confirm("폼메일을 삭제하시겠습니까?")) {
                    await api.console.forms.delete(form);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        onClickSearch() {
            this.page = 1;
            this.search();
        },
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
    computed: {
        code() {
            return this.$route.query.code;
        },
        setTitle() {
            let titleMap = {
                agency: "대리점 개설 문의",
                estimate: "온라인견적 문의",
                as: "A/S 문의"
            }
            return titleMap[this.code]
        }
    },
};
</script>
