var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1200px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._board ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v(_vm._s(_vm.setTitle) + " 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v(_vm._s(_vm.setTitle) + " 상세")]), ['reference'].includes(_vm.board.code) ? [_c('v-layout', {
    staticClass: "mt-4"
  }, [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")])]), _c('v-card', [_c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("카테고리")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.referenceCategories,
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].category,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "category", $$v);
      },
      expression: "board[board.code].category"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("회사명")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].subject,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "subject", $$v);
      },
      expression: "board[board.code].subject"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("회사명(영문)")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].ensubject,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "ensubject", $$v);
      },
      expression: "board[board.code].ensubject"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("업종")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].sectors,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "sectors", $$v);
      },
      expression: "board[board.code].sectors"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("구성공간")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].construction,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "construction", $$v);
      },
      expression: "board[board.code].construction"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("적용제품")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].product,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "product", $$v);
      },
      expression: "board[board.code].product"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("시공일자")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].constructedAt,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "constructedAt", $$v);
      },
      expression: "board[board.code].constructedAt"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 썸네일 "), _c('div', [_vm._v("이미지사이즈 : 282x370(px)")])]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('div', [_vm.board[_vm.board.code].thumb ? [_c('v-layout', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.board[_vm.board.code].thumb = null;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "18"
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-card', {
    staticStyle: {
      "border-style": "dotted",
      "border-width": "2px"
    },
    attrs: {
      "outlined": "",
      "width": "100%"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.board[_vm.board.code].thumb.src,
      "height": "373",
      "contain": ""
    }
  })], 1)] : _c('v-sheet', {
    staticClass: "d-flex",
    staticStyle: {
      "border-style": "dotted",
      "border-width": "2px",
      "cursor": "pointer"
    },
    attrs: {
      "outlined": "",
      "width": "100%",
      "height": "373"
    },
    on: {
      "click": function ($event) {
        var _vm$$refs$thumb, _vm$$refs$thumb$$refs, _vm$$refs$thumb$$refs2, _vm$$refs$thumb$$refs3;
        (_vm$$refs$thumb = _vm.$refs.thumb) === null || _vm$$refs$thumb === void 0 ? void 0 : (_vm$$refs$thumb$$refs = _vm$$refs$thumb.$refs) === null || _vm$$refs$thumb$$refs === void 0 ? void 0 : (_vm$$refs$thumb$$refs2 = _vm$$refs$thumb$$refs.input) === null || _vm$$refs$thumb$$refs2 === void 0 ? void 0 : (_vm$$refs$thumb$$refs3 = _vm$$refs$thumb$$refs2.click) === null || _vm$$refs$thumb$$refs3 === void 0 ? void 0 : _vm$$refs$thumb$$refs3.call(_vm$$refs$thumb$$refs2);
      }
    }
  }, [_c('div', {
    staticClass: "ma-auto"
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "size": "18"
    }
  }, [_vm._v("mdi-file-upload")]), _c('span', [_vm._v("업로드 (.jpg, .png)")])], 1)]), _c('v-spacer')], 2), _c('v-file-input', {
    ref: "thumb",
    staticClass: "d-none",
    on: {
      "change": function (file) {
        return _vm.upload(file).then(function (res) {
          return _vm.$set(_vm.board[_vm.board.code], 'thumb', res);
        });
      }
    }
  })], 1)], 1)], 1)], 1), _c('v-layout', {
    staticClass: "mt-4"
  }, [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("팝업 이미지")]), _c('v-spacer'), _vm.board[_vm.board.code].images.length < 5 ? _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.setImages('plus');
      }
    }
  }, [_vm._v("이미지 추가")]) : _vm._e(), _vm.board[_vm.board.code].images.length > 1 ? _c('v-btn', {
    attrs: {
      "color": "red"
    },
    on: {
      "click": function ($event) {
        return _vm.setImages('minus');
      }
    }
  }, [_vm._v("이미지 삭제")]) : _vm._e()], 1), _c('v-card', [_c('v-col', [_c('v-row', [_vm._l(_vm.board[_vm.board.code].images, function (image, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6"
      }
    }, [image.image ? [_c('v-layout', [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "icon": "",
        "small": "",
        "text": ""
      },
      on: {
        "click": function ($event) {
          image.image = null;
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-img', {
      attrs: {
        "src": image.image.src,
        "width": "100%",
        "height": "96",
        "contain": ""
      }
    })] : [_c('v-sheet', {
      staticClass: "d-flex",
      staticStyle: {
        "cursor": "pointer",
        "border-style": "dashed",
        "border-width": "2px"
      },
      attrs: {
        "outlined": "",
        "rounded": "",
        "width": "100%",
        "height": "146"
      },
      on: {
        "click": function ($event) {
          var _vm$$refs, _vm$$refs$, _vm$$refs$$$refs, _vm$$refs$$$refs$inpu, _vm$$refs$$$refs$inpu2;
          (_vm$$refs = _vm.$refs[`image_${index}`]) === null || _vm$$refs === void 0 ? void 0 : (_vm$$refs$ = _vm$$refs[0]) === null || _vm$$refs$ === void 0 ? void 0 : (_vm$$refs$$$refs = _vm$$refs$.$refs) === null || _vm$$refs$$$refs === void 0 ? void 0 : (_vm$$refs$$$refs$inpu = _vm$$refs$$$refs.input) === null || _vm$$refs$$$refs$inpu === void 0 ? void 0 : (_vm$$refs$$$refs$inpu2 = _vm$$refs$$$refs$inpu.click) === null || _vm$$refs$$$refs$inpu2 === void 0 ? void 0 : _vm$$refs$$$refs$inpu2.call(_vm$$refs$$$refs$inpu);
        }
      }
    }, [_c('div', {
      staticClass: "ma-auto"
    }, [_c('v-icon', {
      attrs: {
        "left": "",
        "size": "20"
      }
    }, [_vm._v("mdi-plus")]), _c('span', [_vm._v("추가")])], 1)]), _c('v-file-input', {
      ref: `image_${index}`,
      refInFor: true,
      staticClass: "d-none",
      attrs: {
        "accept": ".jpg, .png"
      },
      on: {
        "change": function (file) {
          return _vm.upload2(file, index);
        }
      }
    })]], 2)];
  })], 2)], 1)], 1)] : _vm._e(), ['partners'].includes(_vm.board.code) ? [_c('v-layout', {
    staticClass: "mt-4"
  }, [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")])]), _c('v-card', [_c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("지역")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.partnersCategories,
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].region,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "region", $$v);
      },
      expression: "board[board.code].region"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("유형")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].type,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "type", $$v);
      },
      expression: "board[board.code].type"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("쇼룸/대리점명")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].subject,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "subject", $$v);
      },
      expression: "board[board.code].subject"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("주소")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].address,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "address", $$v);
      },
      expression: "board[board.code].address"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("연락처")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].contact,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "contact", $$v);
      },
      expression: "board[board.code].contact"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("상세보기 링크")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].url,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "url", $$v);
      },
      expression: "board[board.code].url"
    }
  })], 1)], 1)], 1)], 1)] : _vm._e(), ['product'].includes(_vm.board.code) ? [_c('v-layout', {
    staticClass: "mt-4"
  }, [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("카테고리 정보")])]), _c('v-card', [_c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.productCategories,
      "label": "1차 카테고리"
    },
    model: {
      value: _vm.board[_vm.board.code].category1,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "category1", $$v);
      },
      expression: "board[board.code].category1"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "2차 카테고리"
    },
    model: {
      value: _vm.board[_vm.board.code].category2,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "category2", $$v);
      },
      expression: "board[board.code].category2"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "3차 카테고리"
    },
    model: {
      value: _vm.board[_vm.board.code].category3,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "category3", $$v);
      },
      expression: "board[board.code].category3"
    }
  })], 1)], 1)], 1)], 1), _c('v-layout', {
    staticClass: "mt-4"
  }, [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")])]), _c('v-card', [_vm.$route.params._board ? _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("순서")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].sequence,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "sequence", $$v);
      },
      expression: "board[board.code].sequence"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 상단 이미지 "), _c('div', [_vm._v("이미지사이즈 : 282x370(px)")])]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('div', [_vm.board[_vm.board.code].image ? [_c('v-layout', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.board[_vm.board.code].image = null;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "18"
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-card', {
    staticStyle: {
      "border-style": "dotted",
      "border-width": "2px"
    },
    attrs: {
      "outlined": "",
      "width": "100%"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.board[_vm.board.code].image.src,
      "height": "373",
      "contain": ""
    }
  })], 1)] : _c('v-sheet', {
    staticClass: "d-flex",
    staticStyle: {
      "border-style": "dotted",
      "border-width": "2px",
      "cursor": "pointer"
    },
    attrs: {
      "outlined": "",
      "width": "100%",
      "height": "373"
    },
    on: {
      "click": function ($event) {
        var _vm$$refs$image, _vm$$refs$image$$refs, _vm$$refs$image$$refs2, _vm$$refs$image$$refs3;
        (_vm$$refs$image = _vm.$refs.image) === null || _vm$$refs$image === void 0 ? void 0 : (_vm$$refs$image$$refs = _vm$$refs$image.$refs) === null || _vm$$refs$image$$refs === void 0 ? void 0 : (_vm$$refs$image$$refs2 = _vm$$refs$image$$refs.input) === null || _vm$$refs$image$$refs2 === void 0 ? void 0 : (_vm$$refs$image$$refs3 = _vm$$refs$image$$refs2.click) === null || _vm$$refs$image$$refs3 === void 0 ? void 0 : _vm$$refs$image$$refs3.call(_vm$$refs$image$$refs2);
      }
    }
  }, [_c('div', {
    staticClass: "ma-auto"
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "size": "18"
    }
  }, [_vm._v("mdi-file-upload")]), _c('span', [_vm._v("업로드 (.jpg, .png)")])], 1)]), _c('v-spacer')], 2), _c('v-file-input', {
    ref: "image",
    staticClass: "d-none",
    on: {
      "change": function (file) {
        return _vm.upload(file).then(function (res) {
          return _vm.$set(_vm.board[_vm.board.code], 'image', res);
        });
      }
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("시리즈 명칭")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].series,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "series", $$v);
      },
      expression: "board[board.code].series"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("소개글")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-textarea', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board[_vm.board.code].introduction,
      callback: function ($$v) {
        _vm.$set(_vm.board[_vm.board.code], "introduction", $$v);
      },
      expression: "board[board.code].introduction"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 썸네일 "), _c('div', [_vm._v("이미지사이즈 : 282x370(px)")])]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('div', [_vm.board[_vm.board.code].thumb ? [_c('v-layout', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.board[_vm.board.code].thumb = null;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "18"
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-card', {
    staticStyle: {
      "border-style": "dotted",
      "border-width": "2px"
    },
    attrs: {
      "outlined": "",
      "width": "100%"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.board[_vm.board.code].thumb.src,
      "height": "373",
      "contain": ""
    }
  })], 1)] : _c('v-sheet', {
    staticClass: "d-flex",
    staticStyle: {
      "border-style": "dotted",
      "border-width": "2px",
      "cursor": "pointer"
    },
    attrs: {
      "outlined": "",
      "width": "100%",
      "height": "373"
    },
    on: {
      "click": function ($event) {
        var _vm$$refs$thumb2, _vm$$refs$thumb2$$ref, _vm$$refs$thumb2$$ref2, _vm$$refs$thumb2$$ref3;
        (_vm$$refs$thumb2 = _vm.$refs.thumb) === null || _vm$$refs$thumb2 === void 0 ? void 0 : (_vm$$refs$thumb2$$ref = _vm$$refs$thumb2.$refs) === null || _vm$$refs$thumb2$$ref === void 0 ? void 0 : (_vm$$refs$thumb2$$ref2 = _vm$$refs$thumb2$$ref.input) === null || _vm$$refs$thumb2$$ref2 === void 0 ? void 0 : (_vm$$refs$thumb2$$ref3 = _vm$$refs$thumb2$$ref2.click) === null || _vm$$refs$thumb2$$ref3 === void 0 ? void 0 : _vm$$refs$thumb2$$ref3.call(_vm$$refs$thumb2$$ref2);
      }
    }
  }, [_c('div', {
    staticClass: "ma-auto"
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "size": "18"
    }
  }, [_vm._v("mdi-file-upload")]), _c('span', [_vm._v("업로드 (.jpg, .png)")])], 1)]), _c('v-spacer')], 2), _c('v-file-input', {
    ref: "thumb",
    staticClass: "d-none",
    on: {
      "change": function (file) {
        return _vm.upload(file).then(function (res) {
          return _vm.$set(_vm.board[_vm.board.code], 'thumb', res);
        });
      }
    }
  })], 1)], 1)], 1), _c('v-divider')], 1), _c('v-layout', {
    staticClass: "mt-4"
  }, [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("정보")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.setItems
    }
  }, [_vm._v("아이템 추가")]), _vm.board[_vm.board.code].items.length > 1 ? _c('v-btn', {
    attrs: {
      "color": "red"
    },
    on: {
      "click": _vm.removeItems
    }
  }, [_vm._v("아이템 삭제")]) : _vm._e()], 1), _vm._l(_vm.board[_vm.board.code].items, function (item, index) {
    return _c('v-card', {
      key: index,
      staticClass: "mt-2"
    }, [_c('v-card-text', {
      staticClass: "py-0"
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_vm._v("제목")]), _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('v-text-field', {
      attrs: {
        "dense": "",
        "outlined": "",
        "hide-details": ""
      },
      model: {
        value: item.subject,
        callback: function ($$v) {
          _vm.$set(item, "subject", $$v);
        },
        expression: "item.subject"
      }
    })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
      staticClass: "py-0"
    }, [_c('v-layout', {
      staticClass: "mt-4"
    }, [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.setProduct(index);
        }
      }
    }, [_vm._v("제품 추가")]), _vm.board[_vm.board.code].items[index].products.length > 1 ? _c('v-btn', {
      attrs: {
        "color": "red"
      },
      on: {
        "click": function ($event) {
          return _vm.removeProduct(index);
        }
      }
    }, [_vm._v("제품 삭제")]) : _vm._e()], 1), _c('v-row', _vm._l(item.products, function (product, index) {
      return _c('v-col', {
        key: index
      }, [_c('v-row', [_c('v-col', {
        attrs: {
          "cols": "6"
        }
      }, [_c('v-btn', {
        attrs: {
          "color": "primary"
        },
        on: {
          "click": function ($event) {
            return _vm.$refs.popupImagesDialog.open(product.images);
          }
        }
      }, [_vm._v("팝업이미지 추가")])], 1)], 1), _c('v-col', {
        attrs: {
          "cols": "12"
        }
      }, [product.image ? [_c('v-layout', [_c('v-spacer'), _c('v-btn', {
        attrs: {
          "icon": "",
          "small": "",
          "text": ""
        },
        on: {
          "click": function ($event) {
            product.image = null;
          }
        }
      }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-img', {
        attrs: {
          "src": product.image.src,
          "width": "100%",
          "height": "96",
          "contain": ""
        }
      })] : [_c('v-sheet', {
        staticClass: "d-flex",
        staticStyle: {
          "cursor": "pointer",
          "border-style": "dashed",
          "border-width": "2px"
        },
        attrs: {
          "outlined": "",
          "rounded": "",
          "width": "100%",
          "height": "146"
        },
        on: {
          "click": function ($event) {
            var _vm$$refs2, _vm$$refs2$, _vm$$refs2$$$refs, _vm$$refs2$$$refs$inp, _vm$$refs2$$$refs$inp2;
            (_vm$$refs2 = _vm.$refs[`product_image_${index}`]) === null || _vm$$refs2 === void 0 ? void 0 : (_vm$$refs2$ = _vm$$refs2[0]) === null || _vm$$refs2$ === void 0 ? void 0 : (_vm$$refs2$$$refs = _vm$$refs2$.$refs) === null || _vm$$refs2$$$refs === void 0 ? void 0 : (_vm$$refs2$$$refs$inp = _vm$$refs2$$$refs.input) === null || _vm$$refs2$$$refs$inp === void 0 ? void 0 : (_vm$$refs2$$$refs$inp2 = _vm$$refs2$$$refs$inp.click) === null || _vm$$refs2$$$refs$inp2 === void 0 ? void 0 : _vm$$refs2$$$refs$inp2.call(_vm$$refs2$$$refs$inp);
          }
        }
      }, [_c('div', {
        staticClass: "ma-auto"
      }, [_c('v-icon', {
        attrs: {
          "left": "",
          "size": "20"
        }
      }, [_vm._v("mdi-plus")]), _c('span', [_vm._v("추가")])], 1)]), _c('v-file-input', {
        ref: `product_image_${index}`,
        refInFor: true,
        staticClass: "d-none",
        attrs: {
          "accept": ".jpg, .png"
        },
        on: {
          "change": function (file) {
            return _vm.upload(file).then(function (res) {
              return _vm.$set(product, 'image', res);
            });
          }
        }
      })]], 2), _c('v-select', {
        attrs: {
          "items": _vm.colors,
          "multiple": "",
          "item-text": "code",
          "item-value": "_id",
          "label": "컬러",
          "placeholder": "컬로값을 선택해주세요"
        },
        model: {
          value: product._colors,
          callback: function ($$v) {
            _vm.$set(product, "_colors", $$v);
          },
          expression: "product._colors"
        }
      }), _c('v-text-field', {
        attrs: {
          "label": "제품명",
          "placeholder": "제품명을 입력해주세요"
        },
        model: {
          value: product.name,
          callback: function ($$v) {
            _vm.$set(product, "name", $$v);
          },
          expression: "product.name"
        }
      }), _c('v-row', [_c('v-col', {
        attrs: {
          "cols": "6"
        }
      }, [_c('v-btn', {
        attrs: {
          "color": "primary"
        },
        on: {
          "click": function ($event) {
            return product.items.push({
              code: null,
              size: null,
              price: null
            });
          }
        }
      }, [_vm._v("추가")])], 1), product.items.length > 1 ? _c('v-col', {
        attrs: {
          "cols": "6"
        }
      }, [_c('v-btn', {
        attrs: {
          "color": "red"
        },
        on: {
          "click": function ($event) {
            return product.items.pop();
          }
        }
      }, [_vm._v("삭제")])], 1) : _vm._e()], 1), _vm._l(product.items, function (item, index) {
        return _c('v-row', {
          key: index
        }, [_c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "제품코드",
            "placeholder": "제품코드"
          },
          model: {
            value: item.code,
            callback: function ($$v) {
              _vm.$set(item, "code", $$v);
            },
            expression: "item.code"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "사이즈",
            "placeholder": "사이즈"
          },
          model: {
            value: item.size,
            callback: function ($$v) {
              _vm.$set(item, "size", $$v);
            },
            expression: "item.size"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "가격",
            "placeholder": "가격"
          },
          model: {
            value: item.price,
            callback: function ($$v) {
              _vm.$set(item, "price", $$v);
            },
            expression: "item.price"
          }
        })], 1)], 1);
      })], 2);
    }), 1)], 1)], 1);
  })] : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1), _c('popup-images-dialog', {
    ref: "popupImagesDialog"
  })], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }