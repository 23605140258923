<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1200px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.query._board" class="headline text-start mt-8">{{ setTitle }} 등록</div>
            <div v-else class="headline text-start mt-8">{{ setTitle }} 상세</div>
            <!--reference 게시판-->
            <template v-if="['reference'].includes(board.code) ">
                <v-layout class="mt-4">
                    <span class="subtitle-2 font-weight-bold">기본정보</span>
                </v-layout>
                <v-card>
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">카테고리</v-col>
                            <v-col cols="10">
                                <v-select v-model="board[board.code].category" :items="referenceCategories" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">회사명</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].subject" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">회사명(영문)</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].ensubject" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">업종</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].sectors" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">구성공간</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].construction" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">적용제품</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].product" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">시공일자</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].constructedAt" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">
                                썸네일
                                <div>이미지사이즈 : 282x370(px)</div>
                            </v-col>
                            <v-col cols="10">
                                <div>
                                    <template v-if="board[board.code].thumb">
                                        <v-layout>
                                            <v-spacer/>
                                            <v-btn icon text @click="board[board.code].thumb = null"><v-icon size="18">mdi-close</v-icon></v-btn>
                                        </v-layout>
                                        <v-card outlined width="100%" style="border-style: dotted; border-width: 2px;">
                                            <v-img :src="board[board.code].thumb.src" height="373" contain />
                                        </v-card>
                                    </template>
                                    <v-sheet v-else outlined width="100%" height="373" class="d-flex" style="border-style: dotted; border-width: 2px; cursor: pointer;" @click="$refs.thumb?.$refs?.input?.click?.()">
                                        <div class="ma-auto">
                                            <v-icon left size="18">mdi-file-upload</v-icon>
                                            <span>업로드 (.jpg, .png)</span>
                                        </div>
                                    </v-sheet>
                                    <v-spacer/>
                                </div>
                                <v-file-input ref="thumb" class="d-none" @change="file => upload(file).then(res => $set(board[board.code], 'thumb', res))" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-layout class="mt-4">
                    <span class="subtitle-2 font-weight-bold">팝업 이미지</span>
                    <v-spacer/>
                    <v-btn v-if="board[board.code].images.length < 5" color="primary" @click="setImages('plus')">이미지 추가</v-btn>
                    <v-btn v-if="board[board.code].images.length > 1" color="red" @click="setImages('minus')">이미지 삭제</v-btn>
                </v-layout>
                <v-card>
                    <v-col>
                        <v-row>
                            <template v-for="image, index in board[board.code].images">
                                <v-col cols="6" :key="index">
                                    <template v-if="image.image">
                                        <v-layout>
                                            <v-spacer />
                                            <v-btn icon small text @click="image.image = null"><v-icon>mdi-close</v-icon></v-btn>
                                        </v-layout>
                                        <v-img :src="image.image.src" width="100%" height="96" contain />
                                    </template>
                                    <template v-else>
                                        <v-sheet outlined rounded width="100%" height="146" class="d-flex" style="cursor: pointer; border-style: dashed; border-width: 2px;" @click="$refs[`image_${index}`]?.[0]?.$refs?.input?.click?.()">
                                            <div class="ma-auto">
                                                <v-icon left size="20">mdi-plus</v-icon>
                                                <span>추가</span>
                                            </div>
                                        </v-sheet>
                                        <v-file-input :ref="`image_${index}`" accept=".jpg, .png" class="d-none" @change="file => upload2(file, index)"/>
                                    </template>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                </v-card>
            </template>


            <!--partners 게시판-->
            <template v-if="['partners'].includes(board.code) ">
                <v-layout class="mt-4">
                    <span class="subtitle-2 font-weight-bold">기본정보</span>
                </v-layout>
                <v-card>
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">지역</v-col>
                            <v-col cols="10">
                                <v-select v-model="board[board.code].region" :items="partnersCategories" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">유형</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].type" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">쇼룸/대리점명</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].subject" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">주소</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].address" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">연락처</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].contact" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">상세보기 링크</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].url" dense outlined hide-details/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>

            <!--product 게시판-->
            <template v-if="['product'].includes(board.code) ">
                <v-layout class="mt-4">
                    <span class="subtitle-2 font-weight-bold">카테고리 정보</span>
                </v-layout>
                <v-card>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col>
                                <v-select v-model="board[board.code].category1" :items="productCategories" label="1차 카테고리" />
                            </v-col>
                            <v-col>
                                <v-text-field v-model="board[board.code].category2" label="2차 카테고리"/>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="board[board.code].category3" label="3차 카테고리"/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-layout class="mt-4">
                    <span class="subtitle-2 font-weight-bold">기본정보</span>
                </v-layout>
                <v-card>
                    <v-card-text v-if="$route.params._board" class="py-0">
                        <v-row align="center">
                            <v-col cols="2">순서</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].sequence" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">
                                상단 이미지
                                <div>이미지사이즈 : 282x370(px)</div>
                            </v-col>
                            <v-col cols="10">
                                <div>
                                    <template v-if="board[board.code].image">
                                        <v-layout>
                                            <v-spacer/>
                                            <v-btn icon text @click="board[board.code].image = null"><v-icon size="18">mdi-close</v-icon></v-btn>
                                        </v-layout>
                                        <v-card outlined width="100%" style="border-style: dotted; border-width: 2px;">
                                            <v-img :src="board[board.code].image.src" height="373" contain />
                                        </v-card>
                                    </template>
                                    <v-sheet v-else outlined width="100%" height="373" class="d-flex" style="border-style: dotted; border-width: 2px; cursor: pointer;" @click="$refs.image?.$refs?.input?.click?.()">
                                        <div class="ma-auto">
                                            <v-icon left size="18">mdi-file-upload</v-icon>
                                            <span>업로드 (.jpg, .png)</span>
                                        </div>
                                    </v-sheet>
                                    <v-spacer/>
                                </div>
                                <v-file-input ref="image" class="d-none" @change="file => upload(file).then(res => $set(board[board.code], 'image', res))" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">시리즈 명칭</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="board[board.code].series" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">소개글</v-col>
                            <v-col cols="10">
                                <v-textarea v-model="board[board.code].introduction" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">
                                썸네일
                                <div>이미지사이즈 : 282x370(px)</div>
                            </v-col>
                            <v-col cols="10">
                                <div>
                                    <template v-if="board[board.code].thumb">
                                        <v-layout>
                                            <v-spacer/>
                                            <v-btn icon text @click="board[board.code].thumb = null"><v-icon size="18">mdi-close</v-icon></v-btn>
                                        </v-layout>
                                        <v-card outlined width="100%" style="border-style: dotted; border-width: 2px;">
                                            <v-img :src="board[board.code].thumb.src" height="373" contain />
                                        </v-card>
                                    </template>
                                    <v-sheet v-else outlined width="100%" height="373" class="d-flex" style="border-style: dotted; border-width: 2px; cursor: pointer;" @click="$refs.thumb?.$refs?.input?.click?.()">
                                        <div class="ma-auto">
                                            <v-icon left size="18">mdi-file-upload</v-icon>
                                            <span>업로드 (.jpg, .png)</span>
                                        </div>
                                    </v-sheet>
                                    <v-spacer/>
                                </div>
                                <v-file-input ref="thumb" class="d-none" @change="file => upload(file).then(res => $set(board[board.code], 'thumb', res))" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                </v-card>
                <v-layout class="mt-4">
                    <span class="subtitle-2 font-weight-bold">정보</span>
                    <v-spacer />
                    <v-btn color="primary" @click="setItems">아이템 추가</v-btn>
                    <v-btn v-if="board[board.code].items.length > 1" color="red" @click="removeItems">아이템 삭제</v-btn>
                </v-layout>
                <v-card v-for="item, index in board[board.code].items" :key="index" class="mt-2">
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="2">제목</v-col>
                            <v-col cols="10">
                                <v-text-field v-model="item.subject" dense outlined hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-text class="py-0">
                        <v-layout class="mt-4">
                            <v-spacer />
                            <v-btn color="primary" @click="setProduct(index)">제품 추가</v-btn>
                            <v-btn v-if="board[board.code].items[index].products.length > 1" color="red" @click="removeProduct(index)">제품 삭제</v-btn>
                        </v-layout>
                        <v-row>
                            <v-col v-for="product, index in item.products" :key="index">
                                <v-row>
                                    <v-col cols="6">
                                        <v-btn color="primary" @click="$refs.popupImagesDialog.open(product.images)">팝업이미지 추가</v-btn>
                                    </v-col>
                                </v-row>
                                <v-col cols="12">
                                    <template v-if="product.image">
                                        <v-layout>
                                            <v-spacer />
                                            <v-btn icon small text @click="product.image = null"><v-icon>mdi-close</v-icon></v-btn>
                                        </v-layout>
                                        <v-img :src="product.image.src" width="100%" height="96" contain />
                                    </template>
                                    <template v-else>
                                        <v-sheet outlined rounded width="100%" height="146" class="d-flex" style="cursor: pointer; border-style: dashed; border-width: 2px;" @click="$refs[`product_image_${index}`]?.[0]?.$refs?.input?.click?.()">
                                            <div class="ma-auto">
                                                <v-icon left size="20">mdi-plus</v-icon>
                                                <span>추가</span>
                                            </div>
                                        </v-sheet>
                                        <v-file-input :ref="`product_image_${index}`" accept=".jpg, .png" class="d-none" @change="file => upload(file).then(res => $set(product, 'image', res))"/>
                                    </template>
                                </v-col>
                                <v-select v-model="product._colors" :items="colors" multiple item-text="code" item-value="_id" label="컬러" placeholder="컬로값을 선택해주세요"/>
                                <v-text-field v-model="product.name" label="제품명" placeholder="제품명을 입력해주세요"/>
                                <v-row>
                                    <v-col cols="6">
                                        <v-btn color="primary" @click="product.items.push({ code: null, size: null, price: null })">추가</v-btn>
                                    </v-col>
                                    <v-col v-if="product.items.length > 1" cols="6">
                                        <v-btn color="red" @click="product.items.pop()">삭제</v-btn>
                                    </v-col> 
                                </v-row>
                                <v-row v-for="item, index in product.items" :key="index">
                                    <v-col cols="4">
                                        <v-text-field v-model="item.code" label="제품코드" placeholder="제품코드"/>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field v-model="item.size" label="사이즈" placeholder="사이즈"/>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field v-model="item.price" label="가격" placeholder="가격"/>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

            <popup-images-dialog ref="popupImagesDialog" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import popupImagesDialog from '@/components/console/boards/popup-images-dialog.vue';

export default {
    components: {
        VDatetimeField,
        NaverSmarteditor,
        popupImagesDialog,
    },
    data() {
        return {
            ready: false,

            productCategories: [
                { text: "WORKSTATION", value: "WORKSTATION" },
                { text: "LAYMON", value: "LAYMON" },
                { text: "EXECUTIVE", value: "EXECUTIVE" },
                { text: "CONFERENCE", value: "CONFERENCE" },
                { text: "PARTITION", value: "PARTITION" },
                { text: "SOFA", value: "SOFA" },
                { text: "CHAIR", value: "CHAIR" },
            ],

            referenceCategories: [
                { text: "INT+OFFICE", value: "INT" },
                { text: "OFFICE", value: "OFFICE" },
                { text: "PUBLIC·EDUCATION", value: "EDUCATION" },
                { text: "MEDICAL", value: "MEDICAL" },
                { text: "DORMITORY", value: "DORMITORY" },
            ],

            partnersCategories: [
                { text: "서울·수도권", value: "Metropolitan" },
                { text: "수도권 외", value: "UnMetropolitan" },
            ],

            colors: [],

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            board: {
                code: this.$route.query.code,

                "product": {
                    category1: null,
                    category2: null,
                    category3: null,
                    introduction: null,
                    series: null,
                    image: null,
                    thumb: null,
                    items: [
                        {
                            subject: null,
                            products: [
                                {
                                    image: null,
                                    images: [
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null }
                                    ],
                                    _colors: [],
                                    name: null,
                                    items: [
                                        {
                                            code: null,
                                            size: null,
                                            price: null
                                        }
                                    ]
                                },
                                {
                                    image: null,
                                    images: [
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null }
                                    ],
                                    _colors: [],
                                    name: null,
                                    items: [
                                        {
                                            code: null,
                                            size: null,
                                            price: null
                                        }
                                    ]
                                },
                                {
                                    image: null,
                                    images: [
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null }
                                    ],
                                    _colors: [],
                                    name: null,
                                    items: [
                                        {
                                            code: null,
                                            size: null,
                                            price: null
                                        }
                                    ]
                                },
                                {
                                    image: null,
                                    images: [
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null }
                                    ],
                                    _colors: [],
                                    name: null,
                                    items: [
                                        {
                                            code: null,
                                            size: null,
                                            price: null
                                        }
                                    ]
                                },
                            ]
                        }
                    ]
                },

                "reference": {
                    subject: null,
                    ensubject: null,
                    category: "INT",
                    sectors: null,
                    construction: null,
                    product: null,
                    thumb: null,
                    images: [
                        { image: null },
                        { image: null },
                        { image: null },
                        { image: null },
                        { image: null }
                    ]
                },

                "partners": {
                    subject: null,
                    region: "Metropolitan",
                    type: null,
                    address: null,
                    contact: null,
                    url: null
                },
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                let { colors } = await api.console.colors.gets();
                this.colors = colors

                if (this.$route.params._board) {
                    var { board } = await api.console.boards.get({ _id: this.$route.params._board });

                    if(["reference"].includes(board.code)) {
                        this.board = { code: board.code, reference: { ...board }  }
                    } else if(["product"].includes(board.code)) {
                        this.board = { code: board.code, product: { ...board }  }
                    } else if(["partners"].includes(board.code)) {
                        this.board = { code: board.code, partners: { ...board }  }
                    }
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                this.board[this.board.code]._id ? await api.console.boards.put({ code: this.board.code, ...this.board[this.board.code] }) : await api.console.boards.post({ code: this.board.code, ...this.board[this.board.code] })
                alert(this.board[this.board.code]._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async upload(files){
            if(!Array.isArray(files)) return await api.resource.post(files, `/res/boards/${this.board.code}`);

            let _ = [];
            for(let file of files){
                _.push(await api.resource.post(file, `/res/boards/${this.board.code}`));
            }

            return _;
        },

        async upload2(file, index) {
            let { src } = await api.resource.post(file, `/res/boards/${this.board.code}/popup`);
            this.$set(this.board[this.board.code].images[index], "image", { src, url: "" })
        },

        setItems() {
            this.board[this.board.code].items.push({
                            subject: null,
                            products: [
                                {
                                    image: null,
                                    images: [
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null }
                                    ],
                                    _colors: [],
                                    name: null,
                                    items: [
                                        {
                                            code: null,
                                            size: null,
                                            price: null
                                        }
                                    ]
                                },
                                {
                                    image: null,
                                    images: [
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null }
                                    ],
                                    _colors: [],
                                    name: null,
                                    items: [
                                        {
                                            code: null,
                                            size: null,
                                            price: null
                                        }
                                    ]
                                },
                                {
                                    image: null,
                                    images: [
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null }
                                    ],
                                    _colors: [],
                                    name: null,
                                    items: [
                                        {
                                            code: null,
                                            size: null,
                                            price: null
                                        }
                                    ]
                                },
                                {
                                    image: null,
                                    images: [
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null },
                                        { image: null }
                                    ],
                                    _colors: [],
                                    name: null,
                                    items: [
                                        {
                                            code: null,
                                            size: null,
                                            price: null
                                        }
                                    ]
                                },
                            ]
                        })
        },
        setProduct(index) {
            this.board[this.board.code].items[index].products.push({
                image: null,
                images: [
                    { image: null },
                    { image: null },
                    { image: null },
                    { image: null },
                    { image: null }
                ],
                _colors: [],
                name: null,
                items: [
                    {
                        code: null,
                        size: null,
                        price: null
                    }
                ]
            })
        },
        removeProduct(index) {
            this.board[this.board.code].items[index].products.pop()
        },
        setImages(type) {
            if (type === "plus") {
                this.board[this.board.code].images.push({image: null})
            } else if (type === "minus") {
                this.board[this.board.code].images.pop()
            }
        },
        removeItems() {
            this.board[this.board.code].items.pop()
        }
    },
    computed: {
        setTitle() {
            let code = this.board.code;
            let titleMap = {
                "product": "Product",
                "reference": "Reference",
                "partners": "Partners"
            }
            return titleMap[code]
        }
    }
};
</script>
